import { Component, OnInit, ChangeDetectionStrategy, Inject, Optional } from '@angular/core';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { IdentityStoreService } from "@modules/identity/store/services/identity-store.service";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { StorageService } from "@core/services/storage.service";
import { SERVER_SIDE } from "../../../../../provider.token";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { AuthService } from "@core/services/auth.service";
import { AuthRedirectService } from "@core/services/auth-redirect.service";

@Component({
  selector: 'app-oidc-redirect',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OidcRedirectComponent implements OnInit {

  private destroy$: Subject<null> = new Subject<null>();
  constructor( private identityStoreService: IdentityStoreService,
               private router: Router,
               private authService: AuthService,
               private authRedirectService: AuthRedirectService,
               @Optional() @Inject(SERVER_SIDE) private serverSide: boolean
               ) { }

  ngOnInit(): void {
      this.authService.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe(({isAuthenticated})=> {
        if(isAuthenticated) {
          this.identityStoreService.fetchUserProfile();
        }
      });
      this.router.events.pipe((filter((navigationEvent: RouterEvent) => Boolean((navigationEvent instanceof NavigationEnd) && navigationEvent.id === 2)))).subscribe(() => {
        this.authRedirectService.checkSavedRedirectRouteAndNavigate();
      })
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
