import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IdentityApiService } from './services/identity-api.service';
import { UserProfile } from '../models/user';
import * as fromAction from './identity.action';
import { identityFeatureKey } from './identity.state';
import { AlertMessageType } from '@modules/shared/enums/alert-message-type';
import { UserLocationStoreService } from '@modules/shared/store/user-location-store/services/users-location.store.service';
import { EffectHelperService } from '@modules/core/services/effect-helper.service';

@Injectable()
export class IdentityEffects {
  fetchUserProfileEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.FetchUserProfile),
      switchMap(() =>
        this.identityApiService.fetchUser().pipe(
          map((user: UserProfile) => {
            this.userLocationStoreService.fetchLocationByUserId(user.sub);
            return this.effectHelperService.handleSuccess(fromAction.AddUserProfile({user}));
          }),
            catchError((error) => {
            // If user not found redirect to register page
            if(error.status === 404) {
              this.router.navigate(['/auth/register']);
            }
            return of(fromAction.AddError({ error }));
          })
        )
      )
    )
  );
  createUserProfileEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CreateUserProfile),
      switchMap((payload) =>
        this.identityApiService.createProfile(payload.user).pipe(
          map((user) => {
            this.userLocationStoreService.fetchLocationByUserId(user.sub);
            this.router.navigate([`/`]);
            return this.effectHelperService.handleSuccess(fromAction.AddUserProfile({ user }))
          }),
          this.effectHelperService.handleError(fromAction.AddError, true, identityFeatureKey)
        )
      )
    )
  );
  updateUserProfileEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UpdateUserProfile),
      switchMap((payload) =>
        this.identityApiService.update(payload.id, payload.user).pipe(
          map((user) => this.effectHelperService.
          handleSuccess(fromAction.AddUserProfile({ user }), true, 'Successfully updated user profile', identityFeatureKey, AlertMessageType.SUCCESS)),
          this.effectHelperService.handleError(fromAction.AddError, true, identityFeatureKey)
        )
      )
    )
  );
  sendVerificationOtpEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SendVerificationOtp),
      switchMap((payload) =>
        this.identityApiService.sendVerificationOtp(payload.campaignId, payload.phoneNo).pipe(
          map(() => this.effectHelperService.
          handleSuccess(fromAction.AddVerificationOtp({ sendOtp: true  }), true, `a_code_has_been_sent_to_phonenumber|${payload.phoneNo}`, identityFeatureKey, AlertMessageType.SUCCESSFUL)),
          this.effectHelperService.handleError(fromAction.AddError, true, identityFeatureKey))
      )
    )
  );

  redeemOtpEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.RedeemCode),
      switchMap((payload) =>
        this.identityApiService.redeemOTP(payload.otp,payload.bookingId,payload.courseId,payload.userId).pipe(
          map((isValidOtp: boolean) =>  this.effectHelperService.
          handleSuccess(fromAction.UpdateRedeemVerifyStatus({ isValidOtp }), true, 'rs_membership_is_valid', identityFeatureKey, AlertMessageType.SUCCESSFUL)),
          this.effectHelperService.handleError(fromAction.AddError, true, identityFeatureKey)
        )
      )
    )
  );

  constructor(
    private router: Router,
    private identityApiService: IdentityApiService,
    private actions$: Actions,
    private userLocationStoreService: UserLocationStoreService,
    private effectHelperService: EffectHelperService
  ) {}
}
